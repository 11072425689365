import './index.css';
import { Container, Row, Col} from 'react-bootstrap'
import Cube from '../Animation/index.js'

const ContentCertificate = () => {
  return (
      <main className="px-3">
        <p className="lead">
        「用<span className="fontColor-highlight-major">好</span>的心、選<span className="fontColor-highlight-major">好</span>的物」
        </p>
        <Container className="lead" fluid>
          <Row>
            <Col className="justify-content-md-center"><h5>準備公告中<Cube></Cube></h5></Col>
          </Row>
        </Container>
      </main>
  );
}

export default ContentCertificate;
