import './index.css';
import { Container, Row, Col} from 'react-bootstrap'

const ContentProducts = () => {
  return (
      <main className="px-3">
        <p className="lead">
        「用<span className="fontColor-highlight-major">好</span>的心、選<span className="fontColor-highlight-major">好</span>的物」
        </p>
        <Container className="lead" fluid>
          <Row>
            <Col className="justify-content-md-center"><h5>第三波商品全部完銷！第四波預購中， 1/22 開始出貨！</h5></Col>
          </Row>
          <Row>
            <Col className="justify-content-md-center">
            <figure class="figure">
              <img src="/images/sell_1.png" class="figure-img img-fluid rounded" alt="..." />
              <figcaption class="figure-caption">一口吃炙燒烏魚子</figcaption>
            </figure>
            </Col>
            <Col className="justify-content-md-center">
            <figure class="figure">
              <img src="/images/sell_2.png" class="figure-img img-fluid rounded" alt="..." />
              <figcaption class="figure-caption">一口吃即食和風鮑魚</figcaption>
            </figure>
            </Col>
          </Row>
        </Container>
      </main>
  );
}

export default ContentProducts;
