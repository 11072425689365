import './App.css';
import ContentHome from './components/Home/index.js';
import ContentProducts from './components/Products/index.js';
import ContentCertificate from './components/Certificate/index.js';
// import LayoutFooter from './components/Footer/index.js';
import React from 'react';
import { Facebook, Instagram } from 'react-bootstrap-icons';
// import { Container, Col, Row ,Image, Navbar} from 'react-bootstrap'



class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = { currentMenu: "home"};
    this.gotoContent = this.gotoContent.bind(this);
  }

  gotoContent(target){
    this.setState({currentMenu:target})
  }

  renderMenuItems(){

    return (
      <nav className="nav nav-masthead justify-content-center align-items-center float-md-end">
        <a className="nav-link" href="#products" onClick={()=>this.gotoContent("products")}>產品介紹</a>
        <a className="nav-link" href="#certificate" onClick={()=>this.gotoContent("certificate")}>檢驗證書</a>
        <a className="nav-link" href="https://www.facebook.com/houselect.foodie/" target="_blank" rel="noreferrer noopener"><Facebook /> 粉絲團</a>
        <a className="nav-link" href="https://www.instagram.com/houselect.foodie/" target="_blank" rel="noreferrer noopener"><Instagram /> 訂閱</a>
        <a className="nav-link" href="https://page.line.me/hou-select" target="_blank" rel="noreferrer noopener"><i className="fab fa-facebook-messenger"></i> Line</a>
      </nav>
    )
  }

  renderContent(){
    switch(this.state.currentMenu){
      case "certificate":
        return <ContentCertificate />    
      case "products":
        return <ContentProducts />   
      case "home":
      default:
        return <ContentHome />
    }
  }

  getContentByMenu(){

  }

  render(){
    return (
      <div></div>
      // <div className="cover-container d-flex w-100 h-100 p-1 mx-auto flex-column">
      //   <header className="mb-auto">
      //     <div>
      //       <h3 className="float-md-start mb-0 ">
      //         <Container>
      //           <Row>
      //             <Navbar.Brand href="/" className="fontColor-highlight-major">
      //               <Image
      //                 alt=""
      //                 src="/Icon-120.png"
      //                 width="120"
      //                 height="120"
      //                 className="d-inline-block"
      //               />
      //             </Navbar.Brand>
      //           </Row>
      //         </Container>
      //       </h3>
      //       {this.renderMenuItems()}
      //     </div>
      //   </header>
      //   {this.renderContent()}
      //   <Container fluid className="lead-container">
      //     <Row xs="auto" className="justify-content-md-center">
      //       <Col className="justify-content-md-center">
      //         <a href="http://7-11.hou-select.com" className="btn btn-lg btn-primary fw-bold border-white" target="_blank" rel="noreferrer noopener"><i className="fas fa-shopping-cart"></i> 7-11 線上購買</a>
      //       </Col>
      //       </Row>
      //   </Container>
      //   <LayoutFooter />
      // </div>
    );
  }
}

export default App;
