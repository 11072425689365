import './index.css';
import { Container, Row, Col ,Image, Carousel} from 'react-bootstrap'

const carouselData = [
  {
    "src":"/images/package_one_bite.png",
    "subject":"頂級一口吃",
    "description":"打開即享受、輕鬆又快速"
  },
  {
    "src":"/images/1.png",
    "subject":"真．厚切烏魚子",
    "description":"嚴選六兩炙燒、厚切處理、香味口感市場第一"
  },
  {
    "src":"/images/2.png",
    "subject":"一口即食鮑魚",
    "description":"獨特醬汁料理，肉質Q彈、富含優質高蛋白質且低脂"
  },
  {
    "src":"/images/package_box_big.png",
    "subject":"精裝手工盒",
    "description":"讓你端得出廚房、更上的了廳堂"
  },
  {
    "src":"/images/package_bag.png",
    "subject":"送禮、自用首選",
    "description":""
  },
];

function getCarousel(){
  return (
    <Carousel className="lead-container">
      {carouselData.map(function(item,index){
        return (
          <Carousel.Item key={index}>
          <Image
            className="d-block w-100"
            src={item.src}
            alt={item.src}
          />
          <Carousel.Caption>
            <h3 className="fontColor-highlight-secondary">{item.subject}</h3>
            <p>{item.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
        )}
        )
      }
    </Carousel>
  )
}

const ContentHome = () => {
  return (
      <main className="px-3">
        <p className="lead">
        「用<span className="fontColor-highlight-major">好</span>的心、選<span className="fontColor-highlight-major">好</span>的物」
        </p>
        <Container className="lead" fluid>
          <Row>
            <Col className="justify-content-md-center"></Col>
          </Row>
        </Container>
        {getCarousel()}        
      </main>
  );
}

export default ContentHome;
